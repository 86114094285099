import { z } from 'zod'

export const PriceCategoryIdDtoSchema = z.union([
  z.literal('accommodation'),
  z.literal('angry-birds'),
  z.literal('baby-cot'),
  z.literal('bath-robe'),
  z.literal('bath-slippers'),
  z.literal('breakfast'),
  z.literal('buffa-summer'),
  z.literal('buffa-summer-family'),
  z.literal('buffet-1'),
  z.literal('buffet-2'),
  z.literal('buffet-3'),
  z.literal('buffet-4'),
  z.literal('buffet-4b'),
  z.literal('christmas-lunch'),
  z.literal('cruise'),
  z.literal('day-spa'),
  z.literal('dinner'),
  z.literal('evening-spa'),
  z.literal('food-cp'),
  z.literal('food-cotton'),
  z.literal('food-ol'),
  z.literal('food-pine-pub'),
  z.literal('food-rakka'),
  z.literal('green-fee'),
  z.literal('gym'),
  z.literal('late-check-out'),
  z.literal('lunch'),
  z.literal('pet-fee'),
  z.literal('sarkanniemi'),
  z.literal('sauna-world'),
  z.literal('spa'),
  z.literal('super-park'),
  z.literal('weekday-spa'),
  z.literal('wellness-package'),
  z.literal('wine-combo1'),
  z.literal('wine-combo2'),
])

export type PriceCategoryIdDto = z.infer<typeof PriceCategoryIdDtoSchema>

export const isPriceCategoryIdDto = (x: unknown): x is PriceCategoryIdDto =>
  PriceCategoryIdDtoSchema.safeParse(x).success
